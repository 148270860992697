import React, { useState } from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rgba } from 'polished';

import { mq } from '../breakpoints';

import NavDrawer from './NavDrawer';
import { Page } from '../types/Page';
import { Site } from '../types/Site';

const HeaderWrapper = styled.div`
  background-color: ${(props) => rgba(props.theme.palette.primary.main, 0.7)};
`;

const Centered = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1048px;
  padding: 12px;

  ${mq.sm(css`
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    padding-bottom: 0;
  `)};
`;

const linkContainer = css`
  display: none;

  ${mq.sm(css`
    display: flex;
    flex-grow: 2;
    justify-content: space-evenly;
    width: 50%;
  `)};
`;

const LeftLinkContainer = styled.div`
  ${linkContainer};
  order: 1;
`;

const RightLinkContainer = styled.div`
  ${linkContainer};
  order: 3;
`;

const H1 = styled.h1`
  font-family: 'Tangerine', cursive;
  font-size: 36px;
  margin: 0 auto;
  order: 2;
  text-align: center;
  width: 100%;

  ${mq.sm(css`
    font-size: 36px;
    max-width: 200px;
  `)}
`;

const linkStyle = css`
  font-size: 14px;
  opacity: 0.8;
  letter-spacing: 1.1px;
  padding: 0 4px;
  text-align: middle;
  text-decoration: none;
  text-transform: uppercase;

  ${mq.md(css`
    font-size: 17px;
  `)}
`;

export const MenuButton = styled.div`
  cursor: pointer;
  left: 8px;
  padding: 5px;
  position: absolute;
  top: 8px;
  width: 70px;
  z-index: 4;

  ${mq.sm(css`
    display: none;
  `)}
`;

export const Bar = styled.div`
  width: 20px;
  height: 2px;
  background-color: ${(props) => props.theme.palette.text.primary};
  margin: 4px 0;
  transition: 0.4s;
`;

export const bar1 = css`
  -webkit-transform: rotate(-45deg) translate(-5px, 2px);
  transform: rotate(-45deg) translate(-5px, 2px);
`;

export const bar2 = css`
  opacity: 0;
`;
export const bar3 = css`
  -webkit-transform: rotate(45deg) translate(-6px, -4px);
  transform: rotate(45deg) translate(-6px, -4px);
`;

interface PropTypes {
  links: Page[];
  owner: Site;
}

function Navbar({ links, owner }: PropTypes) {
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const midpoint = Math.floor(links.length / 2);
  const leftLinks = links.slice(0, midpoint);
  const rightLinks = links.slice(midpoint, links.length);

  function handleMenuClick() {
    setDrawerIsVisible(!drawerIsVisible);
  }

  return (
    <>
      <NavDrawer close={() => setDrawerIsVisible(false)} links={links} visible={drawerIsVisible} />
      <HeaderWrapper>
        <MenuButton aria-label='Show or hide the menu' onClick={handleMenuClick} role='navigation'>
          <Bar css={drawerIsVisible && bar1} />
          <Bar css={drawerIsVisible && bar2} />
          <Bar css={drawerIsVisible && bar3} />
        </MenuButton>
        <Centered>
          <LeftLinkContainer>
            {leftLinks.map((link) => (
              <Link key={link.name} css={linkStyle} to={link.slug}>
                {link.name}
              </Link>
            ))}
          </LeftLinkContainer>
          <H1>{owner.title}</H1>
          <RightLinkContainer>
            {rightLinks.map((link) => (
              <Link key={link.name} css={linkStyle} to={link.slug}>
                {link.name}
              </Link>
            ))}
          </RightLinkContainer>
        </Centered>
      </HeaderWrapper>
    </>
  );
}

export default Navbar;
